import Vue from 'vue'
import router from '../router'
import axios from 'axios'
import store from '../store'
import { computed, watch } from '@vue/composition-api'

const authToken = computed(() => store.getters['auth/getAuthToken'])
watch(() => authToken.value, (newAuthToken) => {
  axiosIns.defaults.headers['Authorization'] = `Bearer ${newAuthToken}`
})

// const baseUrl = 'https://gostockseasy.com/v1/api';
const baseUrl = 'https://stg.gostockseasy.com/v1/api';
const axiosIns = axios.create({
  baseURL: baseUrl,
  // timeout: 1000,
  Accept: '*/*',
  headers: {
    'Authorization': `Bearer ${authToken.value}`,
  }
})

Vue.prototype.http = axiosIns

axiosIns.interceptors.response.use(response => {
  return response;
}, error => {
  console.log('error -->', error, error.response);
  if (error.response.status === 401 && error.response.statusText == "Unauthorized") {
    store.dispatch('auth/logout')
    router.push({ name: 'login' })
  }
  return error;
}

);


export default axiosIns
